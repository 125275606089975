import { useQuery } from '@tanstack/react-query';
import md5 from 'md5';
import React, { createContext, useEffect, useState } from 'react';
import { globalFetchAPI } from '../globalFetch/globalFetchApi';
import { getCookie } from '../utils/helper';

export const GlobalContext = createContext({
  cartProject: [],
  setCartProject: () => [],
  cartCalculationAmount: {},
  setCartCalculationAmount: () => {},
  projectFilter: [],
  setProjectFilter: () => [],
  currentUser: {},
  setCurrentUser: () => {},
  toastMessage: {},
  setToastMessage: () => {},
  accessToken: null,
  setAccessToken: () => '',
  yearFilter: null,
  setYearFilter: () => '',
  initialized: null,
  setInitialized: () => '',
  totalCalculationAmount: {},
  setTotalCalculationAmount: () => {},
  sorting: {},
  setSorting: () => {},
  axisList: [],
  setAxisList: () => [],
  selectedSimulation: {},
  setSelectedSimulation: () => {},
  localTempCart: [],
  setLocalTempCart: () => [],
});

const GlobalProvider = ({ children }) => {
  const access_token = getCookie('csrf_access_token');
  const [cartProject, setCartProject] = useState([]);
  const [toastMessage, setToastMessage] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [accessToken, setAccessToken] = useState(access_token);
  const [yearFilter, setYearFilter] = useState(2022);
  const [initialized, setInitialized] = useState(false);
  const [sorting, setSorting] = useState({ key: 'default_sorting_order', type: 'id' });
  const [axisList, setAxisList] = useState([]);
  const [selectedSimulation, setSelectedSimulation] = useState({});
  const [localTempCart, setLocalTempCart] = useState([]);
  const [totalCalculationAmount, setTotalCalculationAmount] = useState({
    total: 0,
  });
  const [projectFilter, setProjectFilter] = useState({
    department: [],
    axis: [],
    section: {},
  });
  const [cartCalculationAmount, setCartCalculationAmount] = useState({
    total: 0,
    poss_redu: 0,
    funding: 0,
    total_req_amt: 0,
  });

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const { data: userDetailData, refetch } = useQuery(
    ['userDetail'],
    () => globalFetchAPI('user_detail', requestOptions),
    { enabled: false }
  );

  const sortFun = (cart, local) => {
    if (cart && local) {
      const cartSorted = [...cart];
      const localSorted = [...local];
      return {
        cartSorted: cartSorted?.sort((a, b) => a?.request_amt - b?.request_amt),
        localSorted: localSorted?.sort(
          (a, b) => a?.request_amt - b?.request_amt
        ),
      };
    }
    return {
      cartSorted: [],
      localSorted: [],
    };
  };

  useEffect(() => {
    const testFun = sortFun(cartProject, localTempCart);
    if (
      cartProject?.length &&
      md5(JSON.stringify(testFun?.localSorted)) !==
        md5(JSON.stringify(testFun?.cartSorted))
    ) {
      window.onbeforeunload = (event) => {
        const e = event || window.event;
        // Cancel the event
        e.preventDefault();
        if (e) {
          e.returnValue = ''; // Legacy method for cross browser support
        }
        return ''; // Legacy method for cross browser support
      };
    } else {
      window.onbeforeunload = null;
    }
  }, [localTempCart, cartProject]);

  useEffect(() => {
    if (accessToken) {
      refetch();
    }
  }, [accessToken, refetch]);

  useEffect(() => {
    let poss_redu = 0;
    let funding = 0;
    let total_req_amt = 0;
    cartProject?.forEach((item, index) => {
      poss_redu += item.poss_reduction_amt;
      funding += item.poss_grant_amt;
      total_req_amt += item.request_amt;
    });
    setCartCalculationAmount({
      total: poss_redu + funding,
      poss_redu,
      funding,
      total_req_amt,
    });
  }, [cartProject]);

  useEffect(() => {
    if (userDetailData?.profile) {
      setCurrentUser(userDetailData?.profile);
    }
  }, [userDetailData]);

  return (
    <GlobalContext.Provider
      value={{
        cartProject,
        setCartProject,
        cartCalculationAmount,
        setCartCalculationAmount,
        projectFilter,
        setProjectFilter,
        currentUser,
        setCurrentUser,
        toastMessage,
        setToastMessage,
        accessToken,
        setAccessToken,
        yearFilter,
        setYearFilter,
        initialized,
        setInitialized,
        totalCalculationAmount,
        setTotalCalculationAmount,
        sorting,
        setSorting,
        axisList,
        setAxisList,
        selectedSimulation,
        setSelectedSimulation,
        localTempCart,
        setLocalTempCart,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalProvider;
