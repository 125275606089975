import './App.css';
import 'antd/dist/antd.min.css';
import PageLayout from './common/layout';
import { Navigate, Route, Routes } from 'react-router-dom';
import RegisterPage from './pages/authentication/register';
import LoginPage from './pages/authentication/login';
import { useMediaQuery } from 'react-responsive';
import ToastNotification from './common/toast/ToaseMessage';
import InfoModal from './pages/Others/InfoModel';
import NotVerified from './pages/Others/NotVarified';

function App() {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 767px)',
  });

  if (!isDesktopOrLaptop) {
    return <InfoModal />;
  }

  return (
    <div className='app-wrapper'>
      <ToastNotification />
      <Routes>
        <Route path='/' element={<Navigate to='/login' replace />} />
        <Route exact path='/login' name='Login Page' element={<LoginPage />} />
        <Route
          exact
          path='/register'
          name='Register Page'
          element={<RegisterPage />}
        />
        <Route
          exact
          path='/not-verified'
          name='Verification'
          element={<NotVerified />}
        />
        <Route path='*' element={<PageLayout />} />
      </Routes>
    </div>
  );
}

export default App;
