import { useMutation, useQuery } from '@tanstack/react-query';
import {
  Button,
  Card,
  Form,
  Input,
  Select,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../../common/layout/header';
import { GlobalContext } from '../../context/GlobalContext';
import { globalFetchAPI } from '../../globalFetch/globalFetchApi';
import PasswordChecklist from 'react-password-checklist';
import { CheckCircleFilled, CloseCircleOutlined } from '@ant-design/icons';

const RegisterPage = () => {
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    password: '',
    role: '',
    city: '',
    confirm_password: '',
  });
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isPasswordValidate, setIsPasswordValidate] = useState(false);
  const passwordRef = useRef(isPasswordValidate);
  passwordRef.current = isPasswordValidate;
  const [cityOptions, setCityOptions] = useState([]);
  const [newCity, setNewCity] = useState('');
  const { currentUser, setToastMessage } = useContext(GlobalContext);

  const { data: cityData } = useQuery(['city'], () =>
    globalFetchAPI('getall/cities', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
  );

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...formValues }),
  };
  const mutation = useMutation(
    () => globalFetchAPI('user_register', requestOptions),
    {
      onSuccess(data) {
        if (data?.status_code === 201) {
          navigate('/login');
        } else {
          setToastMessage({ type: 'error', msg: t(data?.msg) });
        }
      },
    }
  );

  const registerHandler = (e) => {
    if (Object.keys(formValues).length) {
      delete formValues.confirm_password;
      mutation.mutateAsync();
    }
  };

  useEffect(() => {
    if (currentUser) {
      navigate('/projects');
    }
    // eslint-disable-next-line
  }, [currentUser]);

  useEffect(() => {
    if (cityData?.data?.length) {
      const options = cityData?.data.map((item) => ({
        value: item?._id,
        label: item?._id,
      }));
      options.sort((a, b) => {
        if (a?.value < b?.value) {
          return -1;
        }
        if (a?.value > b?.value) {
          return 1;
        }
        return 0;
      });
      setCityOptions(options);
    }
  }, [cityData]);

  return (
    <>
      <PageHeader />
      <div className='login-wrapper'>
        <Card className='login-form-container' title={t('signup')}>
          <Form
            form={form}
            onFinish={(e) => registerHandler(e)}
            autoComplete='off'
          >
            <Form.Item
              name='name'
              rules={[
                {
                  required: true,
                  message: t('user_name_input'),
                },
              ]}
            >
              <Input
                className='login-input'
                placeholder={t('user_name')}
                onChange={(e) =>
                  setFormValues((prev) => ({ ...prev, name: e.target.value }))
                }
              />
            </Form.Item>

            <Form.Item
              name='email'
              rules={[
                {
                  type: 'email',
                  required: true,
                  message: t('not_valid_email'),
                },
              ]}
            >
              <Input
                className='login-input'
                placeholder={t('email_address')}
                onChange={(e) =>
                  setFormValues((prev) => ({ ...prev, email: e.target.value }))
                }
              />
            </Form.Item>
            <Tooltip
              placement='bottomLeft'
              trigger='focus'
              overlayClassName='password-check-tooltip'
              title={
                <>
                  <span>{t('password_must_have')}</span>
                  <PasswordChecklist
                    className='password-checker'
                    rules={['minLength', 'capital', 'specialChar', 'number']}
                    minLength={8}
                    value={formValues?.password}
                    onChange={(isValid) => {
                      setIsPasswordValidate(isValid);
                      if (formValues?.password) {
                        form.setFields([
                          {
                            name: 'password',
                            errors: isValid
                              ? []
                              : [t('password_not_match_criteria')],
                          },
                        ]);
                      }
                    }}
                    iconComponents={{
                      ValidIcon: <CheckCircleFilled className='valid-icon' />,
                      InvalidIcon: (
                        <CloseCircleOutlined className='invalid-icon' />
                      ),
                    }}
                    messages={{
                      minLength: t('eight_more_chars'),
                      capital: t('at_least_one_capital'),
                      specialChar: t('at_least_one_special'),
                      number: t('at_least_one_number'),
                    }}
                  />
                </>
              }
            >
              <Form.Item
                name='password'
                rules={[
                  {
                    required: true,
                    message: t('user_password_input'),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value && !passwordRef.current) {
                        return Promise.reject(
                          new Error(t('password_not_match_criteria'))
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
                className='tooltip-password-field'
              >
                <Input.Password
                  className='login-input'
                  placeholder={t('user_password')}
                  onChange={(e) =>
                    setFormValues((prev) => ({
                      ...prev,
                      password: e.target.value,
                    }))
                  }
                />
              </Form.Item>
            </Tooltip>

            <Tooltip
              placement='bottomLeft'
              trigger='focus'
              overlayClassName='password-check-tooltip'
              title={
                <>
                  <span>{t('password_not_match_criteria')}</span>
                  <PasswordChecklist
                    className='password-checker'
                    rules={['match']}
                    minLength={8}
                    value={formValues?.password}
                    valueAgain={formValues?.confirm_password}
                    onChange={(isValid) => {}}
                    iconComponents={{
                      ValidIcon: <CheckCircleFilled className='valid-icon' />,
                      InvalidIcon: (
                        <CloseCircleOutlined className='invalid-icon' />
                      ),
                    }}
                    messages={{
                      match: t('match_password'),
                    }}
                  />
                </>
              }
            >
              <Form.Item
                name='confirm_password'
                dependencies={['password']}
                rules={[
                  {
                    required: true,
                    message: t('password_confirm_input'),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(t('password_must_match'))
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  className='login-input'
                  placeholder={t('password_confirm')}
                  onChange={(e) =>
                    setFormValues((prev) => ({
                      ...prev,
                      confirm_password: e.target.value,
                    }))
                  }
                />
              </Form.Item>
            </Tooltip>

            <Form.Item
              name='role'
              rules={[
                {
                  required: true,
                  message: t('role_name_input'),
                },
              ]}
            >
              <Input
                className='login-input'
                placeholder={t('role_name')}
                onChange={(e) =>
                  setFormValues((prev) => ({ ...prev, role: e.target.value }))
                }
              />
            </Form.Item>

            <Form.Item
              name='city'
              rules={[
                {
                  required: true,
                  message: t('city_name_input'),
                },
              ]}
            >
              <Select
                className='sorting-dropdown'
                placeholder={t('city_name')}
                showSearch
                onChange={(e) => {
                  setFormValues((prev) => ({ ...prev, city: e }));
                }}
                onSearch={(e) => {
                  e.length && setNewCity(e);
                }}
              >
                {newCity &&
                  cityOptions.filter((o) => o.value === newCity).length ===
                    0 && (
                    <Select.Option key={newCity} value={newCity}>
                      {newCity}
                    </Select.Option>
                  )}
                {cityOptions.map((city, idx) => (
                  <Select.Option key={idx} value={city?.value}>
                    {t(city?.label)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <Space className='login-footer-btn-wrapper'>
                <Button type='primary' htmlType='submit'>
                  {t('signup')}
                </Button>
                <Typography onClick={() => navigate('/login')}>
                  {t('signin')}
                </Typography>
              </Space>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </>
  );
};

export default RegisterPage;
