import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import i18next from 'i18next';

const { Option } = Select;

const LangSelect = () => {
  const selected = localStorage.getItem('i18nextLng') || 'ja';
  const [langSelect, setLangSelect] = useState(selected);
  useEffect(() => {
    i18next.changeLanguage(langSelect);
    // eslint-disable-next-line
  }, []);
  return (
    <Select
      defaultValue='ja'
      style={{ width: 120 }}
      onChange={(e) => {
        i18next.changeLanguage(e);
        setLangSelect(e);
      }}
      className='lang-select'
    >
      <Option value='en'>English</Option>
      <Option value='ja'>日本語</Option>
    </Select>
  );
};

export default LangSelect;
