import React, { useEffect } from 'react';
import { Table } from 'antd';

const DataTable = (props) => {
  const {
    columns,
    data,
    scroll,
    pagination,
    onRowClick,
    component,
    checkUpdated,
    setCheckUpdated,
  } = props;

  useEffect(() => {
    if (checkUpdated?.id) {
      setTimeout(() => {
        setCheckUpdated(null);
      }, 2000);
    }
    // eslint-disable-next-line
  }, [checkUpdated]);

  return (
    <Table
      onRow={(record) => {
        if (onRowClick) {
          return { onClick: () => onRowClick(record) };
        }
      }}
      rowClassName={(record, index) =>
        checkUpdated && record?.id === checkUpdated?.id && checkUpdated?.updated
          ? 'row-highlight'
          : ''
      }
      components={component}
      scroll={scroll}
      columns={columns}
      dataSource={data}
      pagination={pagination || false}
    />
  );
};

export default DataTable;
