import { Button, Checkbox, Col, Divider, Row, Tooltip } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableModal from './TableModal';
import { ReactComponent as TooltipIcon } from '../../../assets/svg/TooltipIcon.svg';
import { GlobalContext } from '../../../context/GlobalContext';
import axisComment from '../../../data/axisTooltip.json';

const CheckBoxList = (props) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState('');
  const { projectFilter, setProjectFilter } = useContext(GlobalContext);
  const [indeterminate, setIndeterminate] = useState({});
  const { t } = useTranslation();
  const { sidebarData } = props;
  const depData = sidebarData?.find((item) => item.title === 'dep_search');

  useEffect(() => {
    if (projectFilter?.department.length) {
      const section = {};
      depData?.data.forEach((item) => {
        if (projectFilter?.department.includes(item?._id?.name)) {
          if (projectFilter?.section[item?._id?.name]?.length) {
            section[item?._id?.name] = projectFilter?.section[item?._id?.name];
          } else {
            section[item?._id?.name] = item?.section.map(
              (item) => item?.section_name
            );
            delete indeterminate[item?._id?.name];
          }
          if (
            projectFilter?.section[item?._id?.name]?.length < item?.section.length
          ) {
            setIndeterminate((prev) => ({ ...prev, [item?._id?.name]: true }));
          } else {
            setIndeterminate((prev) => ({ ...prev, [item?._id?.name]: false }));
          }
        } else {
          delete indeterminate[item?._id?.name];
        }
      });
      setProjectFilter((prev) => ({ ...prev, section }));
    } else {
      setIndeterminate({});
    }
    // eslint-disable-next-line
  }, [projectFilter.department, sidebarData, setProjectFilter]);

  return (
    <>
      {sidebarData &&
        sidebarData.map((item, index) => (
          <div key={index}>
            <Row className='sider-checklist-wrapper'>
              <h1 className='sider-checklist-title'>{t(item.title)}</h1>
              <Checkbox.Group
                className='sider-checklist-group'
                onChange={(e) => {
                  if (item?.title === 'axis_search') {
                    setProjectFilter((prev) => ({ ...prev, axis: e }));
                  } else {
                    setProjectFilter((prev) => ({ ...prev, department: e }));
                  }
                }}
                key={
                  item?.title === 'axis_search'
                    ? projectFilter?.axis
                    : projectFilter?.department
                }
                defaultValue={
                  item?.title === 'axis_search'
                    ? projectFilter?.axis
                    : projectFilter?.department
                }
              >
                <Row>
                  {item?.data?.slice(0, 10).map((data, idx) => {
                    return (
                      <React.Fragment key={idx}>
                        <Col
                          span={18}
                          style={{ marginBottom: '12px' }}
                          key={idx}
                        >
                          <Checkbox
                            value={data._id?.name}
                            indeterminate={
                              item?.title !== 'axis_search' &&
                              indeterminate[data?._id?.name]
                            }
                            onChange={(e) => {
                              if (
                                !e.target.checked &&
                                item?.title !== 'axis_search'
                              ) {
                                delete projectFilter?.section[e.target.value];
                                delete indeterminate[e.target.value];
                              }
                            }}
                            className='sider-checklist-value'
                          >
                            {data?._id?.name}
                          </Checkbox>
                        </Col>
                        <Col flex='auto'></Col>
                        {item?.title === 'axis_search' &&
                          axisComment?.find((item) => item?.axis === data._id?.name)
                            ?.comment && (
                            <>
                              <Col style={{ margin: '5px' }}>
                                <Tooltip
                                  placement='topLeft'
                                  arrowPointAtCenter
                                  title={
                                    axisComment?.find(
                                      (item) => item?.axis === data._id?.name
                                    )?.comment
                                  }
                                  className='sider-axis-tags-tooltip'
                                >
                                  <TooltipIcon />
                                </Tooltip>
                              </Col>
                              <Col flex='auto'></Col>
                            </>
                          )}
                        <Col
                          span={3}
                          style={{ textAlign: 'right' }}
                          className='sider-checklist-value-count'
                        >
                          {data.count}
                        </Col>
                      </React.Fragment>
                    );
                  })}
                  <Col span={24}>
                    <Button
                      style={{
                        borderColor: '#16828b',
                        color: '#16828b',
                        width: '100%',
                        marginBottom:
                          index === sidebarData.length - 1 && '30px',
                      }}
                      className='sider-checklist-button'
                      onClick={() => {
                        setVisibleModal(true);
                        setSelectedTab(item.title);
                      }}
                    >
                      {item.title === 'axis_search'
                        ? t('select_more_axis')
                        : t('select_more_dep')}
                    </Button>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Row>
            {index !== sidebarData.length - 1 && (
              <Divider className='sider-divider' />
            )}
          </div>
        ))}
      <TableModal
        open={visibleModal}
        setOpen={setVisibleModal}
        currentTab={selectedTab}
        setCurrentTab={setSelectedTab}
        modelData={sidebarData}
      />
    </>
  );
};

export default CheckBoxList;
