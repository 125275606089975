import axisCalculation from '../data/axisCalculation.json';
import { REDUCT_GRANT_FLAG } from '../pages/projectList/constants/Constants';

export const FilterCart = (setCart, value) => {
  setCart((prev) =>
    prev.find((obj) => obj?.id === value?.id)
      ? prev.filter((item) => item?.id !== value?.id)
      : [...prev, { ...value, isManualAmountAdded: false }]
  );
};

export const shortAmount = (value) => {
  return new Intl.NumberFormat('ja-JP', {
    notation: 'compact',
  }).format(value || 0);
};

export const longAmount = (value) => {
  return new Intl.NumberFormat('ja-JP', {
    style: 'currency',
    currency: 'JPY',
  }).format(value || 0);
};

export const numberFormat = (value, isFromCalculation) => {
  if (value) {
    return new Intl.NumberFormat('ja-JP').format(value);
  }
  return isFromCalculation ? 0 : null;
};

export const getCookie = (name) => {
  var cookieArr = document.cookie.split(';');
  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split('=');
    if (name === cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1]);
    }
  }
  return null;
};

const percentCalculation = (data) => {
  let totalReductionPercentage = 0;
  let totalGrantPercentage = 0;
  axisCalculation?.forEach((item) => {
    if (
      data?.axis?.includes(item?.axis) &&
      item?.reduct_grant_flag === REDUCT_GRANT_FLAG.reduction
    ) {
      totalReductionPercentage += +item?.percent;
    }
    if (
      data?.axis?.includes(item?.axis) &&
      item?.reduct_grant_flag === REDUCT_GRANT_FLAG.grant
    ) {
      totalGrantPercentage += +item?.percent;
    }
  });
  totalReductionPercentage =
    totalReductionPercentage > 1 ? 1 : totalReductionPercentage;
  totalGrantPercentage = totalGrantPercentage > 1 ? 1 : totalGrantPercentage;
  return { reduction: totalReductionPercentage, grant: totalGrantPercentage };
};

export const handleCalculation = (projectList) => {
  const amountCalculatedList = projectList.map((project) => {
    const percent = percentCalculation(project);
    return {
      ...project,
      poss_reduction_amt: project?.request_amt * percent.reduction,
      poss_grant_amt: project?.request_amt * percent.grant,
      assess_amt: project?.request_amt * percent.reduction,
      post_assess_amt:
        project?.request_amt - project?.request_amt * percent.reduction,
    };
  });
  return amountCalculatedList;
};

export const handleSingleProjectCalculation = (editFieldObject) => {
  const percent = percentCalculation(editFieldObject);
  return {
    ...editFieldObject,
    poss_reduction_amt: editFieldObject?.request_amt * percent.reduction,
    poss_grant_amt: editFieldObject?.request_amt * percent.grant,
    post_assess_amt:
      editFieldObject?.request_amt -
      editFieldObject?.request_amt * percent.reduction,
  };
};

export const getYaxisHeight = () => {
  let y = 500;
  if (window) {
    y = (window.screen.height * 500) / 900;
    if (y < 300) {
      y = 300;
    } else if (y > 2000) {
      y = 2000;
    }
  }
  return y;
};