import React, { useContext, useEffect, useState } from 'react';
import { Layout, Typography, Divider, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { SearchOutlined } from '@ant-design/icons';
import { ReactComponent as ArrowIcon } from '../../assets/svg/ArrowIcon.svg';
import CheckBoxList from '../../pages/projectList/components/CheckboxList';
import { globalFetchAPI } from '../../globalFetch/globalFetchApi';
import { useQuery } from '@tanstack/react-query';
import { GlobalContext } from '../../context/GlobalContext';
const { Title } = Typography;
const { Sider } = Layout;

const PageSideBar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [sidebarData, setSidebarData] = useState([]);
  const [yearOptions, setYearOptions] = useState([]);
  const { yearFilter, setYearFilter, setProjectFilter } =
    useContext(GlobalContext);
  const { t } = useTranslation();

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const { data: departmentData, refetch: departmentRefech } = useQuery(
    ['department'],
    () =>
      globalFetchAPI(
        `assessment/getall/department/${yearFilter}`,
        requestOptions
      ),
    { enabled: false }
  );

  const { data: axisData, refetch: axisRefetch } = useQuery(
    ['axis'],
    () =>
      globalFetchAPI(`assessment/getall/axis/${yearFilter}`, requestOptions),
    { enabled: false }
  );

  const { data: yearData } = useQuery(['year'], () =>
    globalFetchAPI('assessment/getall/years', requestOptions)
  );

  useEffect(() => {
    departmentRefech();
    axisRefetch();
    // eslint-disable-next-line
  }, [yearFilter]);

  useEffect(() => {
    if (yearData?.data?.length) {
      const options = yearData?.data.map((item) => ({
        value: item?._id,
        label: item?._id,
      }));
      setYearOptions(options);
    }
  }, [yearData?.data]);

  useEffect(() => {
    const data = [];
    data.push({ title: 'dep_search', data: departmentData?.data });
    data.push({ title: 'axis_search', data: axisData?.data });
    setSidebarData(data);
  }, [departmentData, axisData]);

  return (
    <Sider
      className='sider-content-wrapper'
      width={336}
      style={{
        background: '#FFFFFF',
        overflow: 'auto',
        height: 'calc(100vh - 52px)',
        position: 'sticky',
        top: '52px',
      }}
      trigger={null}
      collapsible
      collapsed={collapsed}
    >
      {collapsed ? (
        <div
          className='sider-collapse-content'
          onClick={() => setCollapsed(false)}
        >
          <SearchOutlined color='#16828B' />
          <Title level={5}>{t('search')}</Title>
        </div>
      ) : (
        <>
          <div className='sider-title'>
            <Title level={5}>{t('pj_search')}</Title>
            <ArrowIcon onClick={() => setCollapsed(true)} />
          </div>
          <Divider className='sider-divider' />
          <div className='year-dropdown-wrapper'>
            <h1 className='year-dropdown-title'>{t('year')}</h1>
            <Select
              defaultValue={yearFilter}
              style={{ width: '100%' }}
              onChange={(e) => {
                setYearFilter(e);
                setProjectFilter({ department: [], axis: [], section: {} });
              }}
            >
              {yearOptions.map((year, idx) => (
                <Select.Option key={idx} value={year?.value}>
                  {t(year?.label)}
                </Select.Option>
              ))}
            </Select>
          </div>
          <Divider className='sider-divider' />
          <div className='sider-checkboxlist'>
            {sidebarData && <CheckBoxList sidebarData={sidebarData} />}
          </div>{' '}
        </>
      )}
    </Sider>
  );
};

export default PageSideBar;
