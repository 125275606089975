import { useMutation } from '@tanstack/react-query';
import { Button } from 'antd';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../../common/layout/header';
import { GlobalContext } from '../../context/GlobalContext';
import { globalFetchAPI } from '../../globalFetch/globalFetchApi';

const NotVerified = (props) => {
  const { currentUser, setCurrentUser } = useContext(GlobalContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    if (currentUser?.login_approved) {
      navigate('/projects');
    }
    // eslint-disable-next-line
  }, [currentUser]);

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const mutation = useMutation(() => globalFetchAPI('logout', requestOptions), {
    onSuccess(data) {
      if (data?.logout) {
        setCurrentUser(null);
        navigate('/login');
      }
    },
  });
  return (
    <>
      <PageHeader />
      <div className='not-verified-content-wrapper'>
        <h1>{t('support')}</h1>
        <Button
          type='primary'
          className='logout-btn'
          onClick={() => mutation.mutateAsync()}
        >
          {t('signout')}
        </Button>
      </div>
    </>
  );
};

export default NotVerified;
