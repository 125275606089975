import React, { useContext, useEffect, useState } from 'react';
import { Modal, Popover } from 'antd';
import { globalFetchAPI } from '../../../globalFetch/globalFetchApi';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EllipsisIcon } from '../../../assets/svg/Ellipsis.svg';
import DataTable from '../../../common/datatable/TableData';
import moment from 'moment';
import { GlobalContext } from '../../../context/GlobalContext';
import { numberFormat } from '../../../utils/helper';

const SimulationListModal = (props) => {
  const { open, setOpen } = props;
  const [simulationId, setSimulationId] = useState(null);
  const [simulationListData, setSimulationListData] = useState([]);
  const [isDeleteClick, setIsDeleteClick] = useState(false);
  const [simulationDetailData, setSimulationDetailData] = useState({});
  const [simulationDelete, setSimulationDelete] = useState({});
  const {
    setCartProject,
    setSelectedSimulation,
    setLocalTempCart,
    selectedSimulation,
    setInitialized,
    accessToken,
  } = useContext(GlobalContext);
  const [popoverVisible, setPopoverVisible] = useState({});
  const { t } = useTranslation();

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const { refetch } = useQuery(
    ['simulationList'],
    () =>
      globalFetchAPI('assessment/simulation/getall', requestOptions).then(
        (res) => {
          if (res?.result === 'success') {
            if (res?.data?.length > 0 && !simulationId) {
              const list = res?.data?.map((item, index) => {
                return { key: index, id: item._id, ...item };
              });
              setSimulationListData(list);
            }
          }
        }
      ),
    { enabled: false }
  );

  const { refetch: simulationRefetch } = useQuery(
    ['simulationDetail'],
    () =>
      globalFetchAPI('assessment/simulation/get', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': accessToken,
        },
        body: JSON.stringify({ simulation_id: simulationId }),
      }).then((res) => {
        if (res?.result === 'success') {
          setSimulationDetailData(res);
        }
      }),
    { enabled: false }
  );

  const { refetch: simulationDeleteRefetch } = useQuery(
    ['simulationDelete'],
    () =>
      globalFetchAPI('assessment/simulation/delete', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': accessToken,
        },
        body: JSON.stringify({ simulation_id: simulationId }),
      }).then((res) => {
        if (res?.result === 'success') {
          setSimulationDelete(res);
        }
      }),
    { enabled: false }
  );

  useEffect(() => {
    if (open && !simulationId) {
      refetch();
    } else {
      setPopoverVisible({});
    }
    if (simulationId && isDeleteClick) {
      simulationDeleteRefetch();
    } else if (simulationId) {
      simulationRefetch();
    }
    // eslint-disable-next-line
  }, [open, refetch, simulationId, simulationRefetch, isDeleteClick]);

  useEffect(() => {
    if (simulationId && simulationDetailData?.data?.length) {
      setOpen(false);
      setSimulationId(null);
      const simulationProjects = simulationDetailData?.data?.map((item) => ({
        ...item?.current,
      }));
      setLocalTempCart(simulationProjects);
      setCartProject(simulationProjects);
    }
    if (simulationId && simulationDelete?.result === 'success') {
      if (simulationId === selectedSimulation?.id) {
        setInitialized(false);
        setSelectedSimulation({});
      }
      setIsDeleteClick(false);
      setOpen(false);
      setSimulationId(null);
      setSimulationDelete({});
    }
    // eslint-disable-next-line
  }, [simulationDetailData, simulationDelete]);

  const simulationListColumns = [
    {
      title: t('all_simulation_name'),
      dataIndex: 'name',
    },
    {
      title: t('all_pj_count'),
      dataIndex: 'total_project',
    },
    {
      title: t('all_squeeze_amt'),
      dataIndex: 'fin_twist_out',
      render: (value, record) => <span>{numberFormat(value, true)}</span>,
    },
    {
      title: t('all_pj_amt'),
      dataIndex: 'total_req',
      render: (value, record) => <span>{numberFormat(value, true)}</span>,
    },
    {
      title: t('all_pj_update_date'),
      dataIndex: 'created_date',
      render: (value) => (value ? moment(value).format('YYYY/MM/DD') : ''),
    },
    {
      title: '',
      render: (value) => (
        <>
          <div
            className='simulation-setting-wrapper'
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Popover
              placement='bottomRight'
              id='simulation-item'
              title={
                <div
                  onClick={() => {
                    setPopoverVisible({ [value?.id]: false });
                    setSelectedSimulation({
                      id: value?.id,
                      name: value?.name,
                    });
                    setSimulationId(value?.id);
                  }}
                >
                  <span>{t('all_pj_open')}</span>
                </div>
              }
              trigger='click'
              arrowPointAtCenter={true}
              onVisibleChange={(e) => setPopoverVisible({ [value?.id]: e })}
              visible={popoverVisible[value?.id]}
              overlayClassName={`ellipsis-icon-popover edit-simulation ${open}`}
              content={
                <div
                  onClick={() => {
                    setPopoverVisible({ [value?.id]: false });
                    setIsDeleteClick(true);
                    setSimulationId(value?.id);
                    setSimulationListData(
                      simulationListData?.filter(
                        (item) => item?.id !== value?.id
                      )
                    );
                  }}
                >
                  <span>{t('all_pj_delete')}</span>
                </div>
              }
            >
              <div className='ellipsis-icon-wrapper'>
                <EllipsisIcon />
              </div>
            </Popover>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <Modal
        className='simulation-list-wrapper modal-wrapper'
        title={t('all_list_simulations')}
        width={800}
        visible={open}
        centered
        onOk={() => setOpen(false)}
        onCancel={() => {
          setOpen(false);
          setSimulationId(null);
        }}
        footer={null}
      >
        <DataTable columns={simulationListColumns} data={simulationListData} />
      </Modal>
    </>
  );
};

export default SimulationListModal;
