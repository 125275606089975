import React from 'react';

const ProjectList = React.lazy(() =>
  import('../pages/projectList/ProjectList')
);

const ProjectDetails = React.lazy(() =>
  import('../pages/projectDetails/ProjectDetails')
);

const routes = [
  {
    path: 'projects',
    exact: true,
    component: <ProjectList />,
  },
  {
    path: 'projects/:city/:projectId',
    exact: true,
    component: <ProjectDetails />,
  },
];

export default routes;
