import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { Suspense } from 'react';
import { Outlet, useMatch } from 'react-router-dom';
import RouteComponent from '../../route/route';
import PageHeader from './header';
import PageSideBar from './sidebar';

const PageLayout = (props) => {
  const match = useMatch('projects/:city/:projectId');
  return (
    <Layout>
      <PageHeader />
      <Layout
        className='sider-layout-wrapper'
        style={{ display: 'flex', flexDirection: 'unset' }}
      >
        {!match && <PageSideBar />}
        <Layout>
          <Content style={{ padding: 24, marginTop: '60px' }}>
            <Suspense fallback={<></>}>
              <Outlet />
              <RouteComponent />
            </Suspense>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default PageLayout;
