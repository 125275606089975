//global fetch API
export const globalFetchAPI = async (url, requestOptions) => {
  try {
    // For Local
//    const response = await fetch(
//      `${process.env.REACT_APP_API_URL}/${url}`,
//      requestOptions
//    );

    // For Live (Production & Staging)
     const response = await fetch(`/${url}`, requestOptions);
    const data = await response.json();
    if (response.status === 401) {
        window.location.pathname = '/login'
    }
    return data;
  } catch (error) {
    return false;
  }
};