import React from 'react';
import { Modal } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';

const InfoModal = (props) => {
  return (
    <>
      <Modal
        className='modal-wrapper'
        title={[
          <div key='info'>
            <InfoCircleFilled className='info-icon'/>
            <span>Information</span>
          </div>,
        ]}
        width={920}
        visible={true}
        closeIcon={<></>}
        centered
        footer={null}
      >
        <div>
          <p>Please use computer</p>
        </div>
      </Modal>
    </>
  );
};

export default InfoModal;
