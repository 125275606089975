import React, { useContext, useEffect, useState } from 'react';
import { Col, Layout, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DrawerIcon } from '../../assets/svg/DrawerIcon.svg';
import logoIcon from '../../assets/png/logo-icon.png';
import LangSelect from '../langSelect/LangSelect';
import { GlobalContext } from '../../context/GlobalContext';
import { useLocation, useNavigate } from 'react-router-dom';
import AccountPopover from './popover';
import samplePDF from '../../assets/documents/sample.pdf';
import { QuestionCircleFilled } from '@ant-design/icons';
import SimulationListModal from '../../pages/projectList/components/SimulationListModal';

const { Header } = Layout;

const PageHeader = (props) => {
  const { t } = useTranslation();
  const { currentUser } = useContext(GlobalContext);
  const [match, setMatch] = useState(false);
  const [openSimulationListModal, setOpenSimulationListModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === '/login' || location.pathname === '/register') {
      setMatch(true);
    } else {
      setMatch(false);
    }
  }, [location]);
  return (
    <>
      <Header className='page-header-wrapper'>
        <Row className='page-header-content-wrapper'>
          <Col span={12} style={{ textAlign: 'left' }}>
            <div
              className='logo-title-wrapper'
              onClick={() => navigate('/projects')}
            >
              <img src={logoIcon} className='logo-icon' alt='logo' />
              {match ? (
                <h1 className='title'>{t('ebpmss')}</h1>
              ) : (
                <h1 className='title'>{`${t('ebpmss')} | ${
                  currentUser?.city ?? '〇〇'
                }`}</h1>
              )}
            </div>
          </Col>
          <Col span={12}>
            <div className='right-menu-wrapper'>
              {currentUser?.name && (
                <div
                  className='saved-simulation-text-wrapper'
                  onClick={() => setOpenSimulationListModal(true)}
                >
                  <span>{t('all_list_simulations')}</span>
                </div>
              )}
              <div className='help-menu-icon'>
                <a href={samplePDF} rel='noreferrer' target='_blank'>
                  <QuestionCircleFilled />
                </a>
              </div>
              {process.env.REACT_APP_SHOW_LANG === 'true' && <LangSelect />}
              {!match && (
                <>
                  <h1 className='username-title'>{currentUser?.name}</h1>
                  <AccountPopover>
                    <div className='drawer-icon'>
                      <DrawerIcon />
                    </div>
                  </AccountPopover>
                </>
              )}
            </div>
          </Col>
        </Row>
      </Header>
      <SimulationListModal
        open={openSimulationListModal}
        setOpen={setOpenSimulationListModal}
      />
    </>
  );
};

export default PageHeader;
