/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GlobalContext } from '../../context/GlobalContext';

const ToastNotification = (props) => {
  const { toastMessage, setToastMessage } = useContext(GlobalContext);
  useEffect(() => {
    if (toastMessage?.msg) {
      if (toastMessage?.type === 'error') {
        toast.error(toastMessage?.msg);
      }
      if (toastMessage?.type === 'success') {
        toast.success(toastMessage?.msg);
      }
      setTimeout(() => {
        setToastMessage(null);
      }, 500);
    }
  }, [toastMessage]);

  return (
    <div className='error-toast'>
      <ToastContainer
        position='top-center'
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        limit={1}
      />
    </div>
  );
};

export default ToastNotification;
