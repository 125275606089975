export const SORTING_OPTION_ARRAY = [
  { value: 'default_sorting_order', label: 'default_sorting_order' },
//  { value: 'pj_name', label: 'pj_name' },
//  { value: 'pj_expense_item', label: 'pj_expense_item' },
  { value: 'request_amt', label: 'total_expen_amt' },
  { value: 'poss_reduction_amt', label: 'poss_reduction_amt' },
  { value: 'poss_grant_amt', label: 'poss_grant_amt' },
];

export const REDUCT_GRANT_FLAG = {
  reduction: 'reduction',
  grant: 'grant',
};
