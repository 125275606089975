import routes from './routes';
import { Route, Routes, useNavigate } from 'react-router-dom';
import ParentLevel from './ParentLevel';
import { useContext, useEffect } from 'react';
import { GlobalContext } from '../context/GlobalContext';

const RouteComponent = (props) => {
  const { currentUser, accessToken } = useContext(GlobalContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser && !accessToken) {
      navigate('/login');
    }
    if (currentUser && !currentUser?.login_approved) {
      navigate('/not-verified');
    }
    // eslint-disable-next-line
  }, [currentUser]);

  return (
    <Routes>
      {routes &&
        routes.map((route, idx) => {
          return route.component ? (
            <Route
              key={idx}
              path={route.path}
              element={
                <ParentLevel path={route.path} props={props}>
                  {route.component}
                </ParentLevel>
              }
            />
          ) : null;
        })}
    </Routes>
  );
};

export default RouteComponent;
